/* eslint no-undef:0 */
import "lightgallery.js"

export default class {
  constructor () {
    this.$lightgallery = document.querySelectorAll('.lightgallery')
    this.$defaultOptions = {
      thumbnail: false,
      animateThumb: false,
      showThumbByDefault: false,
      download: false,
      selector: '.item'
    }
    this.$options = {}

    if (this.$lightgallery) {
      this.bindEvents()
    }
  }

  bindEvents () {
    this.$lightgallery.forEach(element => { this.launchLightgalleryHandler(element) })
  }

  launchLightgalleryHandler (element) {
    if (element.dataset.options) {
      this.$options = JSON.parse(element.dataset.options.replace(/'/g, '"'))
    }
    // eslint-disable-next-line
    new lightGallery(element, {
      ...this.$defaultOptions,
      ...this.$options
    })
  }
}
