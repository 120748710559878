import Vue from 'vue'
import VueAgile from 'vue-agile'
import Transitions from 'vue2-transitions'
import VModal from 'vue-js-modal'
import VueScrollTo from 'vue-scrollto'
import PrettyCheckbox from 'pretty-checkbox-vue'
import axios from "axios"
import FlashMessage from "@utils/flash-messages"

// Directive
import vClickOutside from 'v-click-outside'

// dayjs
import dayjs from "dayjs"
import "dayjs/locale/en"
import "dayjs/locale/fr"

// Plugins
import '@plugins/paginations'
import '@plugins/tooltip'

Vue.use(VueAgile)
Vue.use(Transitions)
Vue.use(vClickOutside)
Vue.use(VueScrollTo)
Vue.use(VModal)
Vue.use(PrettyCheckbox)

dayjs.extend(require("dayjs/plugin/updateLocale"))

// Components
/**
 * Automatically register components in the "pages/application" folder.
*/
const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => {
  const component = key.split('/').pop().split('.')[0]

  Vue.component(component, () => import(`${key}`))
})

Vue.component('Lightgallery', () => import('@components/Lightgallery'))
Vue.component('Slick', () => import('@components/Slick'))
Vue.component('ActiveItem', () => import('@components/ActiveItem'))
Vue.component('DestinationSelect', () => import('@components/DestinationSelect'))
Vue.component('BuildingSelect', () => import('@components/BuildingSelect'))
Vue.component('DatePicker', () => import('@components/DatePicker'))
Vue.component('PersonCounter', () => import('@components/PersonCounter'))
Vue.component('ParallaxImageScroll', () => import('@components/ParallaxImageScroll'))
Vue.component('CustomMap', () => import('@components/CustomMap'))
Vue.component('CustomMapMarker', () => import('@components/CustomMapMarker'))
Vue.component('HostCounter', () => import('@components/HostCounter'))
Vue.component('GuideAddress', () => import('@components/GuideAddress'))
Vue.component('InstagramFeed', () => import('@components/InstagramFeed'))
Vue.component('SpecialCriteria', () => import('@components/SpecialCriteria'))
Vue.component('PricePicker', () => import('@components/PricePicker'))
Vue.component('StickyFooterButton', () => import('@components/StickyFooterButton'))
Vue.component('PlaceSearch', () => import('@components/Place/Search'))
Vue.component('LottieAnimationMan', () => import('@components/Animations/LottieAnimationMan'))
Vue.component('LottieAnimationStars', () => import('@components/Animations/LottieAnimationStars'))
Vue.component('DrawLineConcept', () => import('@components/DrawLineConcept'))
Vue.component('RentalAccommodationSearch', () => import('@components/RentalAccommodation/Search'))
Vue.component('ReservationTunnel', () => import('@components/ReservationTunnel'))

Vue.prototype.$eventBus = new Vue()

// Configuration
Vue.config.performance = true
Vue.config.productionTip = false

document.addEventListener('DOMContentLoaded', () => {
  const token = document.head.querySelector('meta[name="csrf-token"]').content
  axios.defaults.headers.common['X-CSRF-Token'] = token
  axios.defaults.headers.common.Accept = 'application/json'
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

  axios.interceptors.response.use((response) => {
    return response
  }, function (error) {
    new FlashMessage("Une erreur s'est produite", "danger")
    return Promise.reject(error)
  })

  new Vue({
    el: '#vue-app'
  })
})
