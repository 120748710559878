import Swiper from 'swiper/bundle'

export default class {
  constructor () {
    this.$slider = document.querySelector('.accommodation-slide')
    this.$swiper = null
    this.$defaultOptions = {
      slidesPerView: 4.2,
      speed: 1100,
      loop: true,
      spaceBetween: 10,
      grabCursor: true,
      noSwiping: true,
      noSwipingClass: 'swiper-slide',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      autoplay: {
        delay: 2500
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 0
        },
        480: {
          slidesPerView: 1.8,
          spaceBetween: 10
        },
        1240: {
          slidesPerView: 2.1,
          spaceBetween: 10
        },
        1600: {
          slidesPerView: 3.1,
          spaceBetween: 10
        }
      }
    }

    this.$options = {}

    if (this.$slider) {
      this.bindEvents()
    }
  }

  bindEvents () {
    this.launchSliderHandler(this.$slider)
  }

  launchSliderHandler (element) {
    this.$swiper = new Swiper(element, this.$defaultOptions)

    this.$swiper.on('slideChange', this.onSlideChangeHandler.bind(this))
    this.$swiper.on('transitionEnd', this.onTransitionEnd.bind(this))
    this.$swiper.on('touchStart', this.onTouchStart.bind(this))

    this.onSlideChangeHandler()
  }

  onSlideChangeHandler () {
    if (this.$swiper.realIndex === 0) {
      this.$slider.classList.add('disable-prev-option')
    } else {
      this.$slider.classList.remove('disable-prev-option')
    }
  }

  onTransitionEnd () {
    this.$swiper.params.speed = 1100
    this.$swiper.params.autoplay.delay = 5000
    this.$swiper.autoplay.start()
  }

  onTouchStart () {
    this.$swiper.params.speed = 150
  }
}
