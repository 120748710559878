// FUNCTION LOADER CONTROLER
// Execute only once the page loader //

export default class {
  constructor () {
    if (window.sessionStorage.sessionLoaderController === undefined) {
      document.querySelector('body').classList.add('trigger-loader')
      window.sessionStorage.setItem('sessionLoaderController', true)
    } else {
      document.querySelector('.loader').style.display = 'none'
    }
  }
}
