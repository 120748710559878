export default class {
  constructor () {
    this.$button = document.querySelector('.reservation-end-button')
    this.$sliderbloc = document.querySelector('.reservation__swiper')
    this.$tybloc = document.querySelector('.reservation-thanks')

    if (this.$button) {
      this.bindEvents()
    }
  }

  bindEvents () {
    this.$button.addEventListener('click', this.openAnimation.bind(this))
  }

  openAnimation () {
    this.$sliderbloc.classList.add('hidden')
    this.$tybloc.classList.remove('hidden')
  }
}
