export default () => {
  const scrollingElements = document.querySelectorAll("[data-animate]")
  const threshold = [0, 0.3, 1]
  const classActive = 'in'

  const obs = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach(entry => {
        entry.target.classList.toggle(classActive, entry.isIntersecting)
      })
    },
    {
      threshold: threshold,
      rootMargin: '100px'
    }
  )
  scrollingElements.forEach(scrollingElement => obs.observe(scrollingElement))
}
