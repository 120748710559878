/* eslint no-console:0 */

// MODULES =====================================================================
import Rails from 'rails-ujs'
import "bootstrap"
import "@appPages/vue"

import "stylesheets/application.sass"
// import InputSmooth from "@utils/input-smooth"
import ImageObjectFit from "@utils/image-object-fit"
import FlashMessage from "@utils/flash-messages"
import ScrollAnimation from "@utils/scroll-animation"
import LoaderController from "@utils/loader-controller"
import MainHeader from "@utils/main-header"
import ConstantHeight from "@utils/constant-height"
import ReservationEnd from "@utils/reservation-end"
import Lightgallery from "@utils/lightgallery"
import AccommodationSwiper from "@utils/accommodation-swiper"

// Fonts ===============================================================
import "typeface-roboto"

// CONFIGURATION ===============================================================
Rails.start()

window.FlashMessage = FlashMessage

window.recaptachCallback = () => {
  const form = document.getElementById('new_contact')
  if (form.reportValidity()) form.submit()
}

document.addEventListener("DOMContentLoaded", () => {
  new LoaderController()
  new ImageObjectFit()
  new MainHeader()
  new ScrollAnimation()
  new ConstantHeight()
  new ReservationEnd()
  new Lightgallery()
  new AccommodationSwiper()
})
