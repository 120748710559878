export default class {
  constructor () {
    // SCROLL VARIABLES -------------------------------------
    this.scrollPosition = window.scrollY
    this.scrollLevelMaxUp = 1
    this.scrollLevelMaxDown = 30 // around header height

    this.scrollClass = 'header--scroll'
    this.minimizedClass = 'header--minimized'

    this.bindEvents()
  }

  changeScrollClass (currentScroll, oldScroll, maxScrollUp, maxScrollDown) {
    const self = this
    const header = document.querySelector('[data-header]')
    if (window.scrollY < maxScrollUp && currentScroll < oldScroll) {
      header.classList.remove(self.scrollClass)
      header.classList.remove(self.minimizedClass)
    } else if (window.scrollY > maxScrollDown) {
      if (currentScroll >= oldScroll) {
        header.classList.add(self.minimizedClass)
      } else if (currentScroll < oldScroll) {
        header.classList.add(self.scrollClass)
        header.classList.remove(self.minimizedClass)
      }
    }
  }

  bindEvents () {
    const self = this
    document.addEventListener('scroll', function () {
      const scroll = window.scrollY
      self.changeScrollClass(scroll, self.position, self.scrollLevelMaxUp, self.scrollLevelMaxDown)
      self.position = scroll
    })
  }
}
